<template>
    <div
        :class="'html-module ' + config.className.value"
        v-html="config.html.value"
    />
</template>

<script>
      
    export default {
        props:["config"],
        mounted(){
            if(!this.config.html){
                this.config["html"] = {value: ""};
            }
            if(!this.config.className){
                this.config["className"] = {value: ""};
            }
            if(typeof this.config.html === 'string'){
                this.config["html"] = {value: this.config.html};
            }
        }
    }
      
</script>
